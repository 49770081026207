import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Hops Wong",
  "date": "2013-07-30 UTC -8",
  "length": 5,
  "location": "Sandy, UT",
  "path": "/first-home/",
  "title": "Our First Home",
  "tags": ["personal", "utah", "lifestyle"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`But we're not there yet, so we don't need to worry about it. In your world you have total and absolute power. If you didn't have baby clouds, you wouldn't have big clouds.`}</p>
    <p>{`It's life. It's interesting. It's fun. And maybe, maybe, maybe... You got your heavy coat out yet? It's getting colder. If you do too much it's going to lose its effectiveness. This is probably the greatest thing that's ever happened in my life.`}</p>
    <p>{`If you don't think every day is a good day - try missing a few. You'll see. Think about a cloud. Just float around and be there. Isn't it great to do something you can't fail at? How do you make a round circle with a square knife? That's your challenge for the day. We don't want to set these clouds on fire. You don't have to spend all your time thinking about what you're doing, you just let it happen.`}</p>
    <p>{`See there, told you that would be easy. Very easy to work these to death. Nature is so fantastic, enjoy it. Let it make you happy. Give him a friend, we forget the trees get lonely too. Let's make some happy little clouds in our world.`}</p>
    <p>{`Clouds are free they come and go as they please. You have freedom here. The only guide is your heart. We wash our brush with odorless thinner. Of course he's a happy little stone, cause we don't have any other kind. You can create beautiful things - but you have to see them in your mind first. We don't have to be committed. We are just playing here.`}</p>
    <p>{`And that's when it becomes fun - you don't have to spend your time thinking about what's happening - you just let it happen. Let your imagination just wonder around when you're doing these things. I'll go over the colors one more time that we use: Titanium white, Thalo green, Prussian blue, Van Dyke brown, Alizarin crimson, Sap green, Cad yellow, and Permanent red.`}</p>
    <p>{`We'll paint one happy little tree right here. All you have to learn here is how to have fun. These little son of a guns hide in your brush and you just have to push them out. Every day I learn. Everyone is going to see things differently - and that's the way it should be.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAP/2gAMAwEAAhADEAAAAVsfNM0tGd//xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAwQTFP/aAAgBAQABBQJWeuhE6RML02sXHin/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8Byj//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARES/9oACAECAQE/Adrl/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAEhMQIRMqH/2gAIAQEABj8CvZKR0Wic/Cz/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhcYFBkf/aAAgBAQABPyHL12Ik+q4J9cgwI0SgUajHAns//9oADAMBAAIAAwAAABCj/wD/xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBcf/aAAgBAwEBPxCDoyf/xAAXEQEAAwAAAAAAAAAAAAAAAAABABEx/9oACAECAQE/ELBsbtZ//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFRgcFB/9oACAEBAAE/ENkKY0vcZ8cBTPcAVVXhUU5B9XyGDm7q7PcNLOaHk//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Front",
          "title": "Front",
          "src": "/static/5f9544cee5e7a8c4f673cb8bb2d0242d/4b190/home_front.jpg",
          "srcSet": ["/static/5f9544cee5e7a8c4f673cb8bb2d0242d/863e1/home_front.jpg 225w", "/static/5f9544cee5e7a8c4f673cb8bb2d0242d/20e5d/home_front.jpg 450w", "/static/5f9544cee5e7a8c4f673cb8bb2d0242d/4b190/home_front.jpg 800w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcor4+WoYF//xAAZEAEAAwEBAAAAAAAAAAAAAAABAgMSEBH/2gAIAQEAAQUCrJTXlANWvGNZn//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAEhMUECEf/aAAgBAQAGPwJzRO0YzrwYj//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/ITQOL2NPFn6nHUF5t9xYMqJfdPZ//9oADAMBAAIAAwAAABAQL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QH//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EKH/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRgf/aAAgBAQABPxDHSMcfpxBh4AjQGXEKCjtIMwz3WXUPIrqD1fkZFEVqlz//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kitchen",
          "title": "Kitchen",
          "src": "/static/6c8fbfb5b38779d73430caee792af2ae/4b190/home_kitchen.jpg",
          "srcSet": ["/static/6c8fbfb5b38779d73430caee792af2ae/863e1/home_kitchen.jpg 225w", "/static/6c8fbfb5b38779d73430caee792af2ae/20e5d/home_kitchen.jpg 450w", "/static/6c8fbfb5b38779d73430caee792af2ae/4b190/home_kitchen.jpg 800w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAcCBmiEoeP/EABwQAAEEAwEAAAAAAAAAAAAAAAEAAgMSBBMhIv/aAAgBAQABBQKH0dTVFjh7Rw2Vl//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhEhEJH/2gAIAQEABj8CI6tLcn3b9P/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBUWH/2gAIAQEAAT8hF4pl5CzH38lLjeTa+wYYsuLZMf/aAAwDAQACAAMAAAAQT9//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8QjH//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/EHVyNO4ZAgvGn55LXRQBKoYQtd3MgH2FoU3dif/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Upstairs",
          "title": "Upstairs",
          "src": "/static/2e1a29e8b9d0fa36b569eab6b37b80fa/4b190/home_upstairs.jpg",
          "srcSet": ["/static/2e1a29e8b9d0fa36b569eab6b37b80fa/863e1/home_upstairs.jpg 225w", "/static/2e1a29e8b9d0fa36b569eab6b37b80fa/20e5d/home_upstairs.jpg 450w", "/static/2e1a29e8b9d0fa36b569eab6b37b80fa/4b190/home_upstairs.jpg 800w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB4Wqqc1iUKP/EABsQAAICAwEAAAAAAAAAAAAAAAABAgMEESEi/9oACAEBAAEFAqvI8qrTfYwLLUOTZ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAAMAAAAAAAAAAAAAAAAAABARUf/aAAgBAgEBPwFwf//EABsQAAICAwEAAAAAAAAAAAAAAAABEiECEDER/9oACAEBAAY/AveCmpZI4Wyknr//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPyFCyw7MhQXddh2rbO4wWoEUh66RDQfCf//aAAwDAQACAAMAAAAQtC//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhQf/aAAgBAgEBPxBW0Sz/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFRwfD/2gAIAQEAAT8QMo7YrKLCFHzsExJypV83A+YOhlmIM0fRLQG8AE//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Backyard",
          "title": "Backyard",
          "src": "/static/ed4084ceea359940374034d13f4b598c/4b190/home_backyard.jpg",
          "srcSet": ["/static/ed4084ceea359940374034d13f4b598c/863e1/home_backyard.jpg 225w", "/static/ed4084ceea359940374034d13f4b598c/20e5d/home_backyard.jpg 450w", "/static/ed4084ceea359940374034d13f4b598c/4b190/home_backyard.jpg 800w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      